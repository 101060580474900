import React from "react";
import classes from "./Main.module.css";
import "animate.css";
import mainImage from "../../Images/7732639_5266.svg";

export default function Main() {
  return (
    <div>
      <section id="home" className={classes.mainImage}>
        <div className={classes.sliderContainer}>
          <div
            className={`animate__animated animate__fadeInLeft ${classes.sliderImage}`}
          >
            <img src={mainImage} alt="mainImage" />
          </div>
          <div
            className={` animate__animated animate__fadeInRight ${classes.sliderTitle}`}
          >
            <p>
              Immerse yourself in <span>unforgettable </span>melodies
              <br></br>
              Book your concert <span>experience</span> now
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
