import React from "react";
import coverImage from "../../Images/logoTwo.png";
import Button from "react-bootstrap/Button";
import classes from "./GalleryCard.module.css";

export default function GalleryCard() {
  return (
    <div>
      <article className={classes.card}>
        <img src={coverImage} alt="coverImage" />
      </article>
    </div>
  );
}
