import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import EventCard from "../../Components/UI/EventCard";
import classes from "./GalleryPage.module.css";
import GalleryCard from "../../Components/UI/GalleryCard";

export default function GalleryPage() {
  return (
    <div>
      {/* <Navbar /> */}
      <section className={classes.galleryContainer}>
        <h2 className={` ${classes.galleryContainerTitle}`}>Gallery</h2>

        <div className={`mt-5 ${classes.galleryCard}`}>
          <h2>wings of love </h2>
          <div
            className={` mt-5 d-flex justify-content-between   ${classes.galleryCardContainer}`}
          >
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
          </div>
        </div>

        {/* <div className={`mt-5 ${classes.galleryCard}`}>
          <h2>wings of love </h2>
          <div
            className={` mt-5 d-flex justify-content-between   ${classes.galleryCardContainer}`}
          >
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
          </div>
        </div>

        <div className={`mt-5 ${classes.galleryCard}`}>
          <h2>wings of love </h2>
          <div
            className={` mt-5 d-flex justify-content-between   ${classes.galleryCardContainer}`}
          >
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
          </div>
        </div>

        <div className={`mt-5 ${classes.galleryCard}`}>
          <h2>wings of love </h2>
          <div
            className={` mt-5 d-flex justify-content-between   ${classes.galleryCardContainer}`}
          >
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
            <GalleryCard />
          </div>
        </div> */}
      </section>
    </div>
  );
}
