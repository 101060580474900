import React from "react";
import classes from "./Footer.module.css";

export default function Footer() {
  return (
    <div style={{ position: "relative" }}>
      <footer>
        <p className={classes.rightsText}>2024 © Tamils culture network, All Rights Reserved</p>

        <p className={classes.madeBy}>Made with   <span style={{color:"white"}}>&#9829;	</span>by pk1798.kumar@gmail.com</p>


        <div className={classes.footer}></div>
      </footer>
    </div>
  );
}
