import React from "react";
import comingsoon from "../../Images/comingSoon.png";
import classes from "./ComingSoon.module.css";

export default function ComingSoon() {
  return (
    <>
      <p style={{textAlign:"center", fontSize:'32px'}}>Events</p>
      <div className={classes.comingContainer}>
        <img style={{width:'350px', height:'300px'}} src={comingsoon} alt="comingsoon" />
      </div>

    </>
  );
}
