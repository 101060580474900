import React from "react";
import classes from "./Navbar.module.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../../Images/logoUp.png";

export default function Navbar() {
  return (
    <div>
      <div className={classes.navbar}>
        <div className={`${classes.navbarContainer} ${classes.container} `}>
          <input type="checkbox" name="" id="" />
          <div className={classes.hamburgerLines}>
            <span className={`${classes.line} ${classes.line1}`}></span>
            <span className={`${classes.line} ${classes.line2}`}></span>
            <span className={`${classes.line} ${classes.line3}`}></span>
          </div>
          <ul className={classes.menuItems} style={{ background: "#fff" }}>
            <li>
              <a href="/">Home</a>
              {/* <Link to="/">Home</Link> */}
            </li>
            <li>
              <a href="/about">About</a>
              {/* <Link to="/about">About</Link> */}
            </li>
            {/* <li>
              <a href="#">Our IPs</a>
            </li> */}
            <li>
              <a href="/gallery">Gallery</a>
              {/* <Link to="/gallery">Gallery</Link> */}
            </li>

            <div className={classes.contactBtn}>
              <li>
                {/* <a href="#">Contactus</a> */}
                <a href="/contact" className={classes.contactBtnResponsive}>
                  <Button
                    className={`ml-5 ${classes.button}`}
                    variant="outline-primary"
                    size="lg"
                    style={{ width: "max-content" }}
                  >
                    Contact us
                  </Button>{" "}
                </a>
              </li>
            </div>
          </ul>
          <Link className={classes.logo} to="/">
            <div className={classes.navTitle}>
              <img className={classes.imgLogo} src={logo} alt="logo" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
