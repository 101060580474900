import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import classes from "./AboutPage.module.css";

export default function AboutPage() {
  return (
    <div>
      {/* <Navbar /> */}
      <div>
        <section className={` ${classes.aboutContainer}`}></section>
        <div className={` ${classes.aboutEventsContainer}`}>
          <div className={` ${classes.aboutEventsContent}`}>
            <h2>About events</h2>
            <p className={`pt-3`}>
              The Success of an Event relies on multiple factors, Planning,
              Preparation, Coordination, Promotion, and Execution. For years, we
              have been learning and exploring every feature and element that
              goes into curating a successful event. The experience and
              professionalism of our brand is what separates TLC Events from
              other ordinary programs.
              <br></br>
              TLC has curated some unique Brands and Events for which we claim
              exclusive rights. We have successfully conducted and executed
              these events through conceptualization and made a niche in the
              Event Management and Entertainment Industry of the Country. We
              hope to create a Revolution in every step of Event Curation and
              pull off successful and unforgettable occasions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
