import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import AboutEvent from "../../Components/AboutEvent/AboutEvent";
import Main from "../../Components/Main/Main";
import UpcomingEvent from "../../Components/UpcomingEvent/UpcomingEvent";
import ComingSoon from "../ComingsoonPage/ComingSoon";

export default function HomePage() {
  return (
    <div>
      {/* <Navbar /> */}
      <Main />
      {/* <UpcomingEvent /> */}
      <ComingSoon />
      <AboutEvent />
    </div>
  );
}
