import React from "react";
import classes from "./EventDetails.module.css";
import coverImage from "../../Images/karthigaiPoo.jfif";
import Button from "react-bootstrap/Button";

export default function EventDetails() {
  return (
    <div>
      <section className={` ${classes.eventSection}`}>
        <div
          className={`d-flex justify-content-between  ${classes.eventContainer}`}
        >
          <div className={classes.eventCardContainer}>
            <div className={classes.card}>
              <img src={coverImage} alt="eventImage" />
            </div>
          </div>

          <div className={`${classes.eventDetailsContainer}`}>
            <div className={`${classes.eventDetailsTitle}`}>
              <h2>title</h2>
            </div>

            <div className={`${classes.eventDetailsAbout}`}>
              <h3>about</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laboriosam voluptates adipisci ad, illum ducimus labore hic
                neque
              </p>
            </div>

            <div className={`${classes.eventDetailsButton}`}>
              <Button
                className={`ml-5 ${classes.button}`}
                variant="outline-primary"
                size="lg"
              >
                Book Now
              </Button>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
