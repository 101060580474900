import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import "animate.css";

import React from "react";
import HomePage from "./Pages/HomePage/HomePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import GalleryPage from "./Pages/GalleryPage/GalleryPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import TestSite from "./Components/Testsite/TestSite";
import Footer from "./Components/Footer/Footer";
import EventDetails from "./Components/EventDetails/EventDetails";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      {/* <EventDetails /> */}
      {/* <TestSite /> */}
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/gallery" element={<GalleryPage />}></Route>
        <Route path="/event/:id" element={<EventDetails />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>

    // <Router>
    //   <Routes>
    //     <Route
    //       path="/"
    //       element={
    //         <Navbar>
    //           <Route index element={<HomePage />} />
    //           <Route path="about" element={<AboutPage />} />
    //           <Route path="contact" element={<ContactPage />} />
    //           <Route path="gallery" element={<GalleryPage />} />
    //         </Navbar>
    //       }
    //     ></Route>
    //   </Routes>
    // </Router>
  );
}

export default App;
