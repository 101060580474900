import React from "react";
import classes from "./AboutEvent.module.css";
import Button from "react-bootstrap/Button";
import aboutimage from "../../Images/logoTwo.png";
import { Link } from "react-router-dom";

export default function AboutEvent() {
  return (
    <div>
      <section className={`${classes.AboutEventContainer}`}>
        <div className={`${classes.AboutEventContent}`}>
          <div className={` ${classes.AboutEventCard}`}>
            <h2>About Events</h2>
            <p className={`p-2 pt-4`}>
              The Success of an Event relies on multiple factors, Planning,
              Preparation, Coordination, Promotion, and Execution. For years, we
              have been learning and exploring every feature and element that
              goes into curating a successful event. The experience and
              professionalism of our brand is what separates TLC Events from
              other ordinary programs. TLC has curated some unique Brands and
              Events for which we claim exclusive rights. We have successfully
              conducted and executed these events through conceptualization and
              made a niche in the Event Management and Entertainment Industry of
              the Country.
            </p>

            <div className={`d-flex justify-content-center`}>
              <Link to={`/about`}>
                <Button
                  className={`ml-5 ${classes.button}`}
                  variant="outline-primary"
                  size="lg"
                >
                  Know More
                </Button>{" "}
              </Link>
            </div>
          </div>
          <div className={`${classes.AboutEventImage}`}>
            <img style={{transform: 'scale(2.5)', filter:"brightness(0.9)"}} src={aboutimage} alt="concertimaage" />
          </div>
        </div>
      </section>
    </div>
  );
}
