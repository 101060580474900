import React from "react";

import classes from "./ContactPage.module.css";

export default function ContactPage() {
  return (
    <div>
      <section className={classes.cotactBg}>
        {/* <img src={contact} alt="contactimage" /> */}
      </section>
      <div
        className={` d-flex   align-items-center justify-content-center flex-wrap ${classes.contactContainer}`}
      >
        <div
          className={`  d-flex flex-column align-items-center justify-content-center  ${classes.addres}`}
        >
          <h2>Address</h2>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Est ex
            aspernatur ipsam amet fugit non ratione eaque odit architecto,
            excepturi
          </p>
        </div>
        <div
          className={` d-flex flex-column align-items-center justify-content-center ${classes.callus}`}
        >
          <h2>Call us</h2>
          <p>9087654321</p>
          <h2>mail us</h2>
          <p>logo@gmail.com</p>
        </div>
      </div>
    </div>
  );
}
